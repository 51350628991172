import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="burger-menu"
export default class extends Controller {
    static targets = ["toggle"]
    connect() {

    }

    toggle(event) {
        const divs = document.querySelectorAll(".box.selected")

        // Entferne die Klasse von allen Divs
        divs.forEach(div => {
            div.classList.remove("selected")
        })

        event.currentTarget.classList.add("selected")

        const addressId = this.element.dataset.addressId
        const hiddenInput = document.querySelector("input.selected_address");
        hiddenInput.setAttribute("value", addressId);

        const submit = document.querySelector("input.select_address");
        submit.disabled = false;

    }
}
